*
{
    margin: 0;
    padding: 0;
}

html,
body
{
    overflow: hidden;
    background-color: black;
}

.webgl
{
    position: fixed;
    top: 0;
    left: 0;
    outline: none;
}

.logo-wrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  z-index: 1;
}

.logo {
  font-family: 'Times New Roman', Times, serif !important;
  font-weight: 0;
  color: white;
  font-size: 6em;
  mix-blend-mode: multiply;
}